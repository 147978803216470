<template>
  <figure class="highlight">
    <div
      v-if="isloading"
      style="
        min-height: 100vh;
        min-width: 100vw;
        display: flex;
        place-items: center;
      "
    >
      <br />
      <b-spinner label="Loading..." style="margin: 0 auto"></b-spinner>
    </div>

    <b-container class="mt-6" fluid v-if="!loading">
      <div class="orders">
        <div
          v-if="isloading"
          style="
            min-height: 100vh;
            min-width: 100vw;
            display: flex;
            place-items: center;
          "
        >
          <br />
          <b-spinner label="Loading..." style="margin: 0 auto"></b-spinner>
        </div>
        <div class="px-5" v-else>
          <h3 style="margin-top: 7rem; margin-bottom: 2rem">
            <span class="text-capitalize">Orders Processed by TNL</span>
            <small class="text-muted">&nbsp;&nbsp;</small>
          </h3>
          <b-nav-form>
            <b-form-input
              class="mr-sm-2"
              placeholder="Filter"
              v-model="filter"
            ></b-form-input>
            <b-button
              size="sm"
              class="my-2 my-sm-0"
              v-if="filter"
              @click="filter = ''"
              >Clear</b-button
            >
          </b-nav-form>
          <b-table
            show-empty
            small
            bordered
            stacked="md"
            :items="pendingOrders"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            class="mt-3"
            :tbody-tr-class="rowClass"
          >
            <template v-slot:cell(DeliveryDate)="row">{{
              row.value | onlyDate
            }}</template>

            <template #cell(OrderTotal)="data">
              {{ Math.round(data.item.OrderTotal) | amount }}
            </template>

            <template v-slot:cell(orderStatus)="row">
              <b :class="rowClass(row.item, 'row')">{{ row.value }}</b>
            </template>
            <template v-slot:cell(actions)="row">
              <b-button
                size="sm"
                @click="handleClick(row.item.OrderID)"
                class="mr-1"
                variant="danger"
                >Details
              </b-button>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-pagination"
          ></b-pagination>

          <hr />
        </div>

        <b-modal
          :title="'Details of Order - ' + clickedCoupon.OrderNumber"
          v-model="detailsModal"
          centered
          hideFooter
          size="xl"
        >
          <div class="pl-8 pr-8">
            <table class="car-options mt-4">
              <!-- <tr>
                <th>Order Number</th>
                <td>:</td>
                <td>{{ clickedCoupon.OrderNumber }}</td>
              </tr>-->
              <tr>
                <th>Order Date</th>
                <td>:</td>
                <td>{{ clickedCoupon.OrderDate | formatDate }}</td>
              </tr>
              <tr v-if="clickedCoupon.SalesOrderNumber">
                <th>Sales Order Date</th>
                <td>:</td>
                <td>{{ clickedCoupon.SalesOrderNumber }}</td>
              </tr>
              <tr>
                <th>Total Amount</th>
                <td>:</td>
                <td>{{ clickedCoupon.OrderTotal | amount }}</td>
              </tr>
            </table>

            <br />

            <table
              class="
                table
                b-table
                cart-table
                table-striped table-bordered table-hover
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Colour</th>
                  <th>Unit(s)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  role="row"
                  v-for="(item, i) in clickedCoupon.orderDetails"
                  :key="i"
                >
                  <td>{{ item.VehicleName }}</td>
                  <td>{{ item.Colour }}</td>
                  <td>{{ item.QuantityRequested }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th></th>
                  <th colspan="2">{{ clickedCoupon.OrderTotal | amount }}</th>
                </tr>
              </tfoot>
              <!---->
            </table>
            <br />

            <section class="first-section">
              <!-- <h1>STRATEGY</h1> -->
              <div class="row2">
                <div>
                  <div
                    :class="
                      clickedCoupon.PaymentConfirmation > 0
                        ? 'container2 approvedSteps'
                        : 'container2 unapprovedSteps'
                    "
                  >
                    <p
                      style="
                        color: white;
                        font-size: 0.9rem;
                        text-align: center;
                      "
                    >
                      {{
                        clickedCoupon.PaymentConfirmation > 0
                          ? "Payment Confirmation \nCompleted"
                          : "Payment Confirmation \nPending"
                      }}
                    </p>
                  </div>
                  <p
                    v-if="clickedCoupon.PaymentConfirmation > 0"
                    style="text-align: center"
                  >
                    {{ clickedCoupon.PaymentConfirmationDate | formatDate }}
                  </p>
                </div>

                <div class="arrow">
                  <div class="line"></div>
                  <div class="point"></div>
                </div>

                <div>
                  <div
                    :class="
                      clickedCoupon.LogisticsApproval > 0
                        ? 'container2 approvedSteps'
                        : 'container2 unapprovedSteps'
                    "
                  >
                    <p
                      style="
                        color: white;
                        font-size: 0.9rem;
                        text-align: center;
                      "
                    >
                      {{
                        clickedCoupon.LogisticsApproval > 0
                          ? "Logistics Approval \nCompleted"
                          : "Logistics Approval \nPending"
                      }}
                    </p>
                  </div>
                  <p
                    v-if="clickedCoupon.LogisticsApproval > 0"
                    style="text-align: center"
                  >
                    {{ clickedCoupon.LogisticsApprovalDate | formatDate }}
                  </p>
                </div>

                <div class="arrow">
                  <div class="line"></div>
                  <div class="point"></div>
                </div>
                <div>
                  <div
                    :class="
                      clickedCoupon.FinalApproval > 0
                        ? 'container2 approvedSteps'
                        : 'container2 unapprovedSteps'
                    "
                  >
                    <p
                      style="
                        color: white;
                        font-size: 0.9rem;
                        text-align: center;
                      "
                    >
                      {{
                        clickedCoupon.FinalApproval > 0
                          ? "Finance Approval \nCompleted"
                          : "Finance Approval \nPending"
                      }}
                    </p>
                  </div>
                  <p
                    v-if="clickedCoupon.FinalApproval > 0"
                    style="text-align: center"
                  >
                    {{ clickedCoupon.FinalApprovalDate | formatDate }}
                  </p>
                </div>

                <div class="arrow">
                  <div class="line"></div>
                  <div class="point"></div>
                </div>
                <div>
                  <div
                    :class="
                      clickedCoupon.MarketingApproval > 0
                        ? 'container2 approvedSteps'
                        : 'container2 unapprovedSteps'
                    "
                  >
                    <p
                      style="
                        color: white;
                        font-size: 0.9rem;
                        text-align: center;
                      "
                    >
                      {{
                        clickedCoupon.MarketingApproval > 0
                          ? "Marketing Approval \nCompleted"
                          : "Marketing Approval \nPending"
                      }}
                    </p>
                  </div>
                  <p
                    v-if="clickedCoupon.MarketingApproval > 0"
                    style="text-align: center"
                  >
                    {{ clickedCoupon.MarketingApprovalDate | formatDate }}
                  </p>
                </div>
              </div>
            </section>
          </div>
        </b-modal>
      </div>
    </b-container>
  </figure>
</template>

<script>
// @ is an alias to /src
//import allCars from "../data/allCars";
// eslint-disable-next-line no-unused-vars
import VehicleService from "../service/vehicle";
import { getData } from "./../utils/storage";

export default {
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      dismissCountDownFail: 0,
      fields: [
        {
          key: "rowNum",
          label: "",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "OrderNumber",
          label: "Order Number",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "OrderDate",
          label: "Date",
          sortable: true,
          sortDirection: "desc",
          formatter: "formatDate"
        },
        {
          key: "OrderTotal",
          label: "Amount",
          sortable: true,
          sortDirection: "desc",
          formatter: "amount"
        },
        {
          key: "orderStatus",
          label: "Status",
          sortable: true,
          sortDirection: "desc" /*,
          class: this.getClass()*/
        },

        /*,
        {
          key: "OwnersName",
          label: "Time Left",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "PaymentStatus",
          label: "Status",
          sortable: true,
          sortDirection: "desc"
        }*/
        { key: "actions", label: "Actions" }
      ],
      gettingCoupons: true,
      clickedCoupon: {},
      detailsModal: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      pageOptions: [5, 10, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      dealerid: 0,
      pendingOrders: [],
      pendingOrderDetails: [],
      loading: false,
      isloading: false,
      selectedorder: {},
      canOrder: true
    };
  },
  created() {
    this.hello();
    const loggedInUser = getData("userInfo");
    this.dealerid = loggedInUser.DealerID;
    this.getprocessedOrders();
    this.canOrder = getData("canOrder");
    if (!this.canOrder) {
      this.showToast();
    }
  },
  computed: {},
  methods: {
    showToast(variant = "danger") {
      this.$bvToast.toast(
        "You have overdue invoices." + this.orderNoteWarning,
        {
          title: `Overdue Invoices`,
          variant: variant,
          solid: true,
          noAutoHide: true
        }
      );
    },
    getprocessedOrders: async function() {
      this.loading = true;
      const { data } = await VehicleService.getProcessedOrders(this.dealerid);
      this.loading = false;
      var that = this;
      data.Data.Orders.forEach(element => {
        element.orderStatus = that.getOrderStatus(element);
      });

      this.pendingOrders = data.Data.Orders;
      this.pendingOrderDetails = data.Data.OrderDetails;
      this.totalRows = this.pendingOrders.length;
    },
    getOrderStatus(order) {
      if (order.FinalApproval != null && order.FinalApproval != 0)
        return "Approved";
      if (order.LogisticsApproval != null && order.LogisticsApproval != 0)
        return "Awaiting Final Approval";
      if (order.MarketingApproval != null && order.MarketingApproval != 0)
        return "Awaiting Logistics Approval";
      if (order.PaymentConfirmation != null && order.PaymentConfirmation != 0)
        return "Awaiting Marketing Approval";
      return "Awaiting Payment Confirmation";
    },
    handleClick(id) {
      this.clickedCoupon = this.pendingOrders.filter(n => n.OrderID === id)[0];
      this.clickedCoupon.orderDetails = this.pendingOrderDetails.filter(
        n => n.OrderID === id
      );
      this.selectedorder.OrderID = id;
      this.selectedorder.PaymentStatus = 0;
      this.detailsModal = true;
      console.log("clicked coupon: ", this.clickedCoupon);
    },
    removeOrder(orderid) {
      let orders = this.pendingOrders;
      this.pendingOrders = orders.filter(function(obj) {
        return obj.OrderID !== orderid;
      });
    },
    saveInfo: async function() {
      this.detailsModal = false;
      console.log("Selected Order: ", this.selectedorder);
      try {
        const { data } = await VehicleService.updateOrderStatus(
          this.selectedorder
        );
        if (data.Data) {
          this.showSuccessAlert();
          this.removeOrder(this.selectedorder.OrderID);
          this.selectedorder = {};
        } else {
          this.showFailureAlert();
        }
        console.log("data: ", data);
      } catch (ex) {
        this.showFailureAlert();
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    countDownChangedFail(dismissCountDown) {
      this.dismissCountDownFail = dismissCountDown;
    },
    showSuccessAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showFailureAlert() {
      this.dismissCountDownFail = this.dismissSecs;
    },
    // getClass(item) {
    //   console.log("item", item);
    //   return "bg-danger";
    // },
    rowClass(order, type) {
      if (order && type === "row") {
        if (order.FinalApproval != null && order.FinalApproval != 0) {
          return "text-success font-weight-bold";
        } else if (
          order.LogisticsApproval != null &&
          order.LogisticsApproval != 0
        ) {
          return "text-info font-weight-bold";
        } else if (
          order.MarketingApproval != null &&
          order.MarketingApproval != 0
        ) {
          return "text-warning font-weight-bold";
        } else {
          return "text-danger font-weight-bold";
        }
      } else {
        return null;
      }
    }
  },
  filters: {},
  watch: {}
};
</script>
<style scoped>
.mt-6 {
  margin-top: 4rem !important;
}
</style>
